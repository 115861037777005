<template>
  <!-- 商品秒杀页 -->
  <div class="commodity-spike" v-if="getgoCollagetypelist.grouponInfo"  >
    <van-nav-bar
        title="参与拼团"
        fixed
        left-text=""
        left-arrow
        @click-left="$goBack()"
      />
    <div class="content">
      <div class="box">
        <div class="goods-list">
          <!-- 秒杀商品列表 -->
          <div class="goods-item">
            <div class="title">
              <!-- 用户点击跳转时间 传店铺id -->
              <img
                @click="JumpStore()"
                class="brand-log"
                width="25px"
                height="25px"
                style="border-radius:4px;"
                :src="getgoCollagetypelist.grouponInfo.shopInfo.imgUrl"
                alt="品牌logo"
              />
              <span class="store-name"> {{ getgoCollagetypelist.grouponInfo.shopInfo.name}}</span>
            </div>
            <div class="good-desc">
              {{ getgoCollagetypelist.grouponInfo.name }}
            </div>
            <div class="join-poeple">
                <span class="join-rule">{{getgoCollagetypelist.grouponNum}}人团</span>
              <span class="poeple-num"> 已有{{ getgoCollagetypelist.grouponInfo.launchNum }}人参加 </span>
              <span class="rule" @click="openRule()"> 拼团规则 </span>
            </div>
            <!-- 砍价规则 -->
            <div class="good-info">
              <img class="good-img" :src="getgoCollagetypelist.grouponInfo.goodsSpu.picUrls[0]" alt="商品图片" />
              <div class="desc">
                <span class="good-name van-multi-ellipsis--l2">{{ getgoCollagetypelist.grouponInfo.name }}</span>
               
                <span class="specifications" v-if=" getgoCollagetypelist.grouponInfo.goodsSku && getgoCollagetypelist.grouponInfo.goodsSku.specs.length">
                  
                  
                  <span  v-for="item  in  getgoCollagetypelist.grouponInfo.goodsSku.specs" :key="item.id">

                    {{item.specValueName}}
                  </span>
                  
                </span>
                <div class="prices">
                  <div class="activeprice"> ￥{{ getgoCollagetypelist.grouponInfo.grouponPrice }} </div>
                  <div class="orginprice">￥{{ getgoCollagetypelist.grouponInfo.goodsSku.salesPrice }}</div>
                  <!-- <div class="lable">包邮</div> -->
                </div>
              </div>
            </div>
            <div class="robbing" @click="getId()" v-if="getgoCollagetypelist.grouponUser===null && timer!=-1 &&getgoCollagetypelist.status==='0'">参与拼团</div>
            <div class="robbing" @click="showShare=true" v-if="getgoCollagetypelist.grouponUser &&timer!=-1 &&getgoCollagetypelist.status==='0'">邀请好友拼团</div>

            <div class="robbing"   v-if="getgoCollagetypelist.grouponUser && getgoCollagetypelist.status==='1'">拼团完成</div>
            <div class="robbing"  v-if="getgoCollagetypelist.status==='2' || timer==='-1'">活动已结束</div>
            <div class="time">{{getgoCollagetypelist.grouponInfo.validBeginTime}}至{{getgoCollagetypelist.grouponInfo.validEndTime}}</div>
          </div>
          
          <!-- <div class="frends">
            <div class="title">
              <div class="left"></div>
              已有两个拼单,可直接参与
            </div>
            <div class="hlep-list">
              <div class="hlep-item">
                <img
                  class="frends-img"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng937d685200ea609ed637f640af2ee9cabe93e75320ebe1f4a3b5b2762080f5b8"
                  alt=""
                />
                <div class="frends-name">小江...</div>
                <div class="collage-info">
                    <span>5天13小时39分08秒</span>
                    <p>还差
                        <span>1人</span>
                        拼成</p>
                </div>
                <div class="frends-help-money"> <div>去拼单</div></div>
              </div>
            </div>
          </div> -->
          <div class="warp" v-if="timer">

          <div class="ramainning-time" v-if=" timer!='-1'" >
              倒计时:<span>{{timer}}</span>
          </div>
          <div class="member" v-if="imgs.length>0">
              <div class="tuanzhang" >
                  <img :src="imgs[0].headimgUrl" alt="">

                  <span >{{imgs[0].isLeader === '1'?'团长':imgs[0].isLeader === '0'?'':''}}</span>
              </div>
              <div v-if="imgs[1]" ><img :src="imgs[1].headimgUrl" alt=""></div>
              <span v-if="imgs.length>2"  style="color:#ff7200;font-size:0.6rem;padding-bottom:0.26rem;">…</span>
              <div v-if="imgs[imgs.length-1]&&imgs.length>2"><img :src="imgs[imgs.length-1].headimgUrl" alt=""></div>
              <div><img src="../../assets/next.png" alt=""></div>

          </div>
          </div>
          <div class="footer" v-if="getgoCollagetypelist.grouponInfo.goodsSpu.description">
            <div class="title">商品信息</div>
            <div class="richtext" v-html="getgoCollagetypelist.grouponInfo.goodsSpu.description">
              
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="rule-info" v-show="isShow">
              <div class="rule-box fade-out-fwd" >
                <div class="rule-title">
                  规则说明 <span class="close" @click="closeRule()">X</span>
                </div>
                <div class="rule-content">{{getgoCollagetypelist.grouponInfo.grouponRule}}</div>
              </div>
            </div>
             <van-share-sheet
  v-model="showShare"
  title="立即分享给好友"
  cancel-text=""
  :options="options"
  @select="onSelect"
/>
  </div>
</template>

<script>
import { getgoCollagetype ,getGroupImgs} from '@/api/goCollager/goCollager'
export default {
  data() {
    return {
      showShare:false,
      getgoCollagetypelist:{},
      userInfo:'',
      isShow: false,
      isBargain: false,
      timer:'',
      timerId:0,
      imgs:[],
      options: [
          { name : '微信'   ,  icon: 'wechat',index:0 },
          { name : '朋友圈'  , icon: 'wechat-moments', index:1 },
          { name : 'QQ'     ,  icon: 'qq' ,index:2},
       
      ],
    };
  },
  created(){
     
      //   if(navigator.userAgent.indexOf('万民') == -1){

      //       this.openApp()
      //       return

      // }

     this.id = this.$route.query.id
     if(this.$route.query.session){

            this.$store.commit('setSession',this.$route.query.session)

     }
     
     this.getgoCollagetypepell()
  },
  methods: {
    getgoCollagetypepell(){
     getgoCollagetype(this.id).then(res =>{
       if(res.data.code===0){
         console.log(res,"拼团详情");
        this.getgoCollagetypelist = res.data.data
        getGroupImgs({groupId: this.getgoCollagetypelist.groupId}).then(res=>{
          console.log(res,'团员头像');
          this.imgs= res.data.data.records
        })


        if(this.getgoCollagetypelist.status - 0===0){
          this.getgotime(this.getgoCollagetypelist.validEndTime
          )
        }
        
       }
       
     })
    },
    getTime(index) {
      if (index === this.active) {
        return;
      }
      console.log(index);
      this.active = index;
      this.getActive;
    },
    onSelect(index) {
      console.log(index.index)
      this.shareInfo({
      title:this.getgoCollagetypelist.grouponInfo.name,
      picUrl:this.getgoCollagetypelist.grouponInfo.goodsSpu.picUrls[0]|| "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge075b8c61cca1d61d6fd515ef1b8fe29671548b08ff1d214b42b6e4dded6c95e",
      platformId:index.index,
      openUrl:'',
      content:this.getgoCollagetypelist.grouponInfo.shareTitle
    }) 
      this.showShare = false;
    },
     getgotime(timestamp) {
       clearInterval(this.timerId)
       var nowTime = Date.now();
                var endTime = new Date(timestamp.replace(/-/ig,"/") ).getTime()
                var t =  (endTime - nowTime)
                if(t<0){
                  this.timer=-1
                  this.$router.back()
                  return
                }
                var hour=Math.floor(t/1000/60/60%24);
                   var min=Math.floor(t/1000/60%60);
                   var sec=Math.floor(t/1000%60);
                if (hour < 10) {
                  if(hour<1){
                    hour=''
                  }else{
                     hour = hour +"时";
                  }
                }else{
                   hour = hour +"时";
                }
                if (min < 10) {
                     if(min<1){

                       if(hour!=''){
                         min='00分'
                       }else{
                         min=''
                       }
                   
                  }else{
                     min = "0" + min +"分";
                  }
                }else{
                     min = min +"分";
                  }
                if (sec < 10) {
                   if(sec<1){
                     if(min!=''){
                         sec='00秒'
                       }else{
                         sec=''
                       }
                  }else{
                     sec = "0" + sec +"秒";
                  }
                }else{
                   sec =sec +"秒";
                }
               this.timer=  hour +   min +   sec;
     this.timerId= setInterval(()=>{
                var nowTime = Date.now();
                var endTime = new Date(timestamp.replace(/-/ig,"/") ).getTime()
                var t =  (endTime -nowTime)
                 if(t<0){
                  this.timer=-1
                  clearInterval(this.timerId)
                  this.$router.back()

                  return
                }

                var hour=Math.floor(t/1000/60/60%24);
                   var min=Math.floor(t/1000/60%60);
                   var sec=Math.floor(t/1000%60);
                  
               if (hour < 10) {
                  if(hour<1){
                    hour=''
                  }else{
                     hour = hour +"时";
                  }
                }else{
                   hour = hour +"时";
                }
                if (min < 10) {
                     if(min<1){

                       if(hour!=''){
                         min='00分'
                       }else{
                         min=''
                       }
                   
                  }else{
                     min = "0" + min +"分";
                  }
                }else{
                     min = min +"分";
                  }
                if (sec < 10) {
                   if(sec<1){
                     if(min!=''){
                         sec='00秒'
                       }else{
                         sec=''
                       }
                  }else{
                     sec = "0" + sec +"秒";
                  }
                }else{
                   sec =sec +"秒";
                }
                
            this.timer=  hour +   min +   sec;

       },1000);
  },
    
    getId() {
      //   获取用户点击的商品id 并跳转到相应的页面
         if( (this.getgoCollagetypelist.grouponInfo.isBuy-0)){ 
              
              this.$toast('不可重复购买该活动商品')
              return

          }
            this.data={
            type:2,
            price:parseFloat(this.getgoCollagetypelist.grouponInfo.goodsSku.salesPrice).toFixed(2),
            name:this.getgoCollagetypelist.grouponInfo.goodsSpu.name,
            subtitle:this.getgoCollagetypelist.shareTitle,
            activeprice:parseFloat(this.getgoCollagetypelist.grouponPrice).toFixed(2),
            relationId:this.getgoCollagetypelist.groupId,
            skuId:this.getgoCollagetypelist.grouponInfo.goodsSku.id,
            spuId:this.getgoCollagetypelist.grouponInfo.goodsSpu.id,
            quantity:1,
            paymentWay:2,
            deliveryWay:1,
            img:this.getgoCollagetypelist.grouponInfo.goodsSpu.picUrls[0],
            markedId:this.getgoCollagetypelist.grouponId
         }
        
        this.$router.push({
            path:'/activeOrder',
            query:{
           data:JSON.stringify(this.data) 
          }
        })
       
    },
    JumpStore() {
      //获取店铺id 并跳转到店铺首页
    },
    closeRule() {
      this.isShow = false;
    },
    openRule() {
      this.isShow = true;
    },
    setRem() {
      let screenWidth = document.documentElement.getBoundingClientRect().width;
      console.log(111);
      document.documentElement.style.fontSize = screenWidth / 10 + "px";
    },
    openShare(){

    }
  },
  mounted() {
    this.setRem();
    window.addEventListener("orientationchange", this.setRem);
    window.addEventListener("resize", this.setRem);
  },
  beforeDestroy() {
    document.documentElement.style.fontSize = "16px";
  },
};
</script>

<style lang="less" scoped>
.head {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding-top: 0.533333rem;
  background-color: white;
  padding-bottom: 0.266667rem;
  border-bottom: 1px solid #ccc;
  z-index: 100;
}
.head .back {
  width: 0.666667rem;
  height: 0.64rem;
  float: left;
  margin-left: 0.533333rem;
}
.head .title {
  float: right;
  margin-right: 50%;
  transform: translateX(50%);
  font-size: 0.453333rem;
  font-weight: 600;
}
.commodity-spike {
  background-color: #F6F6F6;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
}
.commodity-spike::-webkit-scrollbar{
  display: none;
}
.content {
  
  overflow: auto;
  
  height: 100%;
  
}
.content::-webkit-scrollbar {
  display: none;
}
.box{
  padding-top: 2rem;
  height: auto;
  padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/

}
.content .list {
  width: 90%;
  display: flex;
  margin: auto;
  align-items: center;
  color: white;
  margin-bottom: 0.293333rem;
  overflow-x: auto;
}
.remainning-time {
  margin: 0.693333rem 0 1.12rem 0.853333rem;
  height: 0.56rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.56rem;
}
.remainning-time span {
  color: #ff5200ff;
}
.goods-item {
  margin:  0 .266667rem .266667rem;
  background-color: white;
  box-sizing: border-box;
  border-radius: .133333rem;
  padding: .266667rem .453333rem;

}
.goods-item .title {
  display: flex;
  align-items: center;
}
.title .brand-logo {
  width: 0.666667rem;
  height: 0.666667rem;
}
.title .store-name {
  font-size: 0.4rem;
  font-weight: 600;
  padding-left: 0.266667rem;
}
.good-desc {
  height: 0.56rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 0.56rem;
  margin-top: 0.24rem;
  margin-bottom: 0.266667rem;
}
.good-info {
  display: flex;
  font-size: 0.4rem;
}
.good-info .good-img {
  width: 2.426667rem;
  height: 2.426667rem;
  box-sizing: border-box;
  margin-right: 0.266667rem;
  border-radius: .266667rem;
} 
.desc .good-name {
 min-height: 1.12rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.56rem;
  margin-bottom: 0.186667rem;
}
.desc .activeprice {
  color: #a80000;
  font-weight: 600;
  margin-right: 0.266667rem;
}
.specifications {
  width: 4.88rem;
  height: 0.453333rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 0.453333rem;
  margin-bottom: 0.106667rem;
}
.prices{
  display: flex;
  align-items: center;
}
.desc .orginprice {
  color: #999;
  text-decoration: line-through;
  font-weight: 600;
}
.desc .lable {
  width: 0.853333rem;
  height: 0.426667rem;
  font-size: .32rem;
  background-color: #ff5200ff;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.83);
  color: white;
  margin-left: 0.266667rem;
}
.join-poeple {
  display: flex;
  align-items: center;
  margin-bottom: 0.373333rem;
}
.join-rule{
    display: inline-block;
    padding: 0 5px;   
    border: 1px solid #FF5200FF;
    margin-right: .266667rem;
    color:#FF5200FF ;
    display: flex;
    align-items: center;
    justify-content: center;
}
.poeple-num {
  height: 0.453333rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999;
  flex: 1;
}
.rule {
  height: 0.453333rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #005bff;
  line-height: 0.453333rem;
  
  text-align: right;
}
.robbing {
  width: 80%;
  height: 0.986667rem;
  color: white;
  font-size: 0.4rem;
  font-weight: 600;
  background-image: url("../../assets/img/commoditySpike/btn.png");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 0.533333rem auto 0.266667rem;
  display: flex;
  align-items: center;
  justify-content: center;

}
.time {
  width: 6.72rem;
  height: 0.453333rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 0.453333rem;
  text-align: center;
  width: 100%;
}

.bargain-info {
  padding: 0.4rem 0.853333rem 0;
  font-size: 0.4rem;
}
.bargain-info .bargain-data {
  height: 0.56rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.56rem;
}
.bargain-info .bargain-data span {
  color: #ff5200ff;
  height: 0.56rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;

  line-height: 0.56rem;
}
.proportion {
  width: 100%;
  margin-top: 0.533333rem;
  height: 0.4rem;
  border-radius: 0.213333rem;
  background: #eaeef5;
}
.readyhave {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: white;

  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.453333rem;
}
.proportion-item {
  position: relative;
  width: 50%;
  height: 100%;
  background-color: #e64d42ff;
  border-radius: 0.213333rem;
  overflow: hidden;
}
.frends {
  padding: 1.146667rem 0.853333rem 0.8rem;
  width: 100%;
  box-sizing: border-box;
}
.frends .title {
  display: flex;
  align-items: center;
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.56rem;
  margin-bottom: .373333rem;
}
.frends .left {
  width: 0.16rem;
  height: 0.666667rem;
  background-color: #e64d42ff;
  border-radius: 0.133333rem;
  margin-right: 0.266667rem;
}
.hlep-item {
  display: flex;
  align-items: center;
  padding: 0.373333rem 0;
  border-bottom: 1px solid #d8d8d8;
}
.frends-name {
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.56rem;
}
.collage-info{
font-size:.32rem;
    color: #E64D42FF;
    float: right;
    flex: 1;
    text-align: right;
    padding-right: .266667rem;
}
.collage-info span{
    margin-bottom: .266667rem;
     color: #E64D42FF;
}
.collage-info p{
    color: black;
     padding-top: .133333rem;
}
.collage-info p span{
     color: #E64D42FF;
}
/* .frends-help-money {
  
  text-align: right;
  color: #e64d42ff;
  font-size:.16rem;
  font-weight: 600;
}
.frends-help-money div {
    width: 1.36rem;
    height: .693333rem;
    font-size: .32rem;
    float: right;
    background: #E64D42FF;
  margin-right: 0.426667rem;
  color: white;
  text-align: center;
  line-height:.693333rem ;
  border-radius: .4rem;
}

.frends-img {
  width: 1.333333rem;
  height: 1.333333rem;
  border-radius: 50%;
  margin-right: 0.266667rem;
} */
.member{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    img{ 
      border-radius: 10%;
    }
}
.member div{
    width: 1.333333rem;
    height: 1.333333rem;
   padding: 0 0.13rem;

}
.member span{
display: flex;
align-items: center;
height: 100%;

}
.ramainning-time{
    margin-bottom: .32rem;
    padding-left: .853333rem;
    font-size: .4rem;
    font-weight: 600;
}
.ramainning-time span{
    color: #FF5200FF;
    margin-left: .266667rem;

}
.warp{
  background-color: white;
  border-radius: .133333rem;
  margin: 0 .266667rem;
  padding:  .426667rem 0 .533333rem;
}

.member div img{
    width: 100%;
    height: 100%;
}
.tuanzhang{
    position: relative;
    :last-child{
      position: absolute;
      border: 1px solid #FF892A;
      height: .426667rem;
      background-color: #FF892A;
      color: #fff;
      top: 0;
      right:0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: .32rem;
      transform: scale(0.83);
      img{ 
        border-radius: 10%;
      }
    }
}
.member div .lable{
   height: .586667rem;
   width: .906667rem;
 position: absolute;
   top: 0;
   right: 0;
}
.footer {
  padding-top: .4rem;
  text-align: center;
  margin: .266667rem ;
  background-color: white;
  border-radius: .133333rem;
  
}
.footer .title {
  height: 0.56rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.56rem;
  margin-bottom: .266667rem;
}
.richtext * {
  width: 100%;
  padding: 0 0.426667rem;
  box-sizing: border-box;
 
}
.rule-info {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  z-index: 10000;
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.rule-box {
  font-size: 0.426667rem;
  background-color: white;
  width: 80%;
  border-radius: 0.133333rem;
  overflow: hidden;
  z-index: 2000;
}
.rule-title {
  text-align: center;
  padding: 0.266667rem 0;
    background-color: white;
}
.rule-title span {
  float: right;
  margin-right: 0.533333rem;
  color: #ff7200ff;
}
.rule-content {
     
  height: 100%;
  width: 100%;
  padding: 0.266667rem 0.426667rem  .433333rem;
  background-color: #ccc;
  box-sizing: border-box;
  white-space: pre-wrap;
  
}

/* 
动画
*/
.fade-out-fwd {
  -webkit-animation: fade-out-fwd 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: fade-out-fwd 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes fade-out-fwd {
  0% {
    -webkit-transform: translateZ(100);
    transform: translateZ(100);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    opacity: 1;
  }
}
@keyframes fade-out-fwd {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    opacity: 1;
  }
}
</style>